@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@400;500;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-hanken bg-theme-background;
}

input[type="file"]::file-selector-button {
  @apply h-full -ml-4 mr-4 px-4 text-sm bg-primary text-white;
}

input[type="file"]:hover::file-selector-button {
  @apply bg-primary-hover;
}

input[type="radio"]:checked {
  background-image: none !important;
  background-size: 0 !important;
  appearance: auto !important;
  border-width: 16px !important;
}

input[type="radio"] {
  appearance: auto !important;
  border-width: 16px !important;
}

.inserted-html {
  table {
    td {
      padding-right: 1rem;
    }
  }
  a {
    color: #2b3ca7;
  }
}
